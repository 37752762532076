import React, { useEffect, useState } from "react";
import JotformEmbed from "react-jotform-embed";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { BlocksContent } from "@global";
import { Query } from "@graphql-types";
import { TABLET_BREAKPOINT } from "@util/constants";
import { useStore } from "@state/store";

const FormWrapper = styled.div`
  overflow-y: auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 87%;
    margin: auto;
  }
`;

let formEmbedUrl = `https://form.jotform.com/${process.env.GATSBY_ENQUIRY_FORM_ID}`;

function EnquiryForm() {
  const { selectedCalendar, drawerVisible } = useStore();
  const [formEmbedURL, setFormEmbedURL] = useState(formEmbedUrl);

  useEffect(() => {
    if (drawerVisible) {
      const formEmbedUrlAppend = `${formEmbedUrl}?productId=${selectedCalendar.productId}&productName=${selectedCalendar.productName}`;
      setFormEmbedURL(formEmbedUrlAppend);
    }
  }),
    [drawerVisible];

  const { allSanityEnquiryForm }: Query = useStaticQuery(graphql`
    {
      allSanityEnquiryForm {
        nodes {
          region {
            countryCode
          }
          formText {
            _rawBlockContent
          }
          formSuccessText {
            _rawBlockContent
          }
        }
      }
    }
  `);

  const filteredFormText = allSanityEnquiryForm.nodes.filter(data => {
    if (data == null || data.region == null) return null;
    return data.region.countryCode === process.env.GATSBY_REGION;
  });

  const enquiryFormData = filteredFormText[0];

  return (
    <FormWrapper>
      <BlocksContent data={enquiryFormData?.formText} />
      <JotformEmbed src={formEmbedURL} />
    </FormWrapper>
  );
}

export default EnquiryForm;
